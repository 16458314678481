import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

/**
 * Functional component which displays loading icon
 * @returns {*}
 * @constructor
 */
export default function Loading(props) {
	// Destructure props
	const {marginTop, align, size} = props;
	// Initialize value
	let textAlign;
	// Switch align property
	switch (align) {
		case 'left':
			textAlign = 'left';
			break;
		case 'right':
			textAlign = 'right';
			break;
		default:
			textAlign = 'center';
	}

	return (
		<>
			<Grid container>
				<Grid
					item
					xs={12}
					style={{
						textAlign: textAlign,
						marginTop: marginTop ? `${marginTop}px` : '0'
					}}
				>
					<CircularProgress
						size={(size && !isNaN(size) && size > 0) ? size : 40}
					/>
				</Grid>
			</Grid>
		</>
	);
}
