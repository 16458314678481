import { createAction } from "../action";

export const ActionTypes = {
	SetVehicleBrandName: "SET_VEHICLE_BRAND_NAME",
	AddServiceOperation: "ADD_SERVICE_OPERATION",
	RemoveServiceOperation: "REMOVE_SERVICE_OPERATION",
	SetWorkshopLocationName: "SET_WORKSHOP_LOCATION_NAME",
	SetWorkshopLocationAddress: "SET_WORKSHOP_LOCATION_ADDRESS",
	SetAppointmentDateTime: "SET_APPOINTMENT_DATE_TIME",
	SetClientLanguageName: "SET_CLIENT_LANGUAGE_NAME",
	SetServiceDurationDays: "SET_SERVICE_DURATION_DAYS",
}

export const Actions = {
	setVehicleBrandName: (payload) => createAction(ActionTypes.SetVehicleBrandName, payload),
	addServiceOperation: (payload) => createAction(ActionTypes.AddServiceOperation, payload),
	removeServiceOperation: (payload) => createAction(ActionTypes.RemoveServiceOperation, payload),
	setWorkshopLocationName: (payload) => createAction(ActionTypes.SetWorkshopLocationName, payload),
	setWorkshopLocationAddress: (payload) => createAction(ActionTypes.SetWorkshopLocationAddress, payload),
	setAppointmentDateTime: (payload) => createAction(ActionTypes.SetAppointmentDateTime, payload),
	setClientLanguageName: (payload) => createAction(ActionTypes.SetClientLanguageName, payload),
	setServiceDurationDays: (payload) => createAction(ActionTypes.SetServiceDurationDays, payload),
};
