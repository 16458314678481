import React, { Component } from "react";
import styled from "styled-components";
import { FormattedMessage } from 'react-intl';
import {companyPhoneNo, companyEmail} from '../../config/envConfig'
import {Typography} from "@material-ui/core";

const FooterContainer = styled.div`
  text-align: center;
  width: 100% !important;
  background: #bbb;
  color: white;
  height: 70px;
`;

const FooterText = styled.div`
	height: 100%;
	display:flex;
	justify-content: center;
	align-items: center;
`;

export class Footer extends Component {
	render() {
		return (
			<FooterContainer>
				<FooterText>
					<Typography variant="body1">
						<FormattedMessage id="Footer.centerText" defaultMessage="Potrebna vam je pomoć?" />
						&nbsp;{companyPhoneNo} | {companyEmail}
					</Typography>
				</FooterText>
			</FooterContainer>
		);
	}
}

export default Footer;
