import React from "react";
import {makeStyles} from "@material-ui/core";
import classnames from 'classnames';
import {
  Assignment,
  AssignmentTurnedIn,
  Build,
  DirectionsCar,
  EventAvailable,
  Home,
  PersonOutline
} from "@material-ui/icons";
import theme from "../../config/theme";

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.primary.main
  },
  completed: {
    backgroundColor: theme.palette.primary.main
  },
});

function getIconComponent(tabNumber) {
  if(!tabNumber || isNaN(tabNumber) || tabNumber <= 0 || tabNumber > 8) {
    return <></>;
  }

  switch (tabNumber) {
    case 1:
      return Home;
    case 2:
      return Build;
    case 3:
      return EventAvailable;
    case 4:
      return PersonOutline;
    case 5:
      return DirectionsCar;
    case 6:
      return Assignment;
    case 7:
      return AssignmentTurnedIn;
    default:
      return Home;
  }
}

export default function CustomStepIcon(props) {
  const classes = useStepIconStyles();
  // Destructure properties
  const { active, completed, icon } = props;
  // Get current icon
  const CurrentIcon = getIconComponent(icon);

  return (
    <div
      className={classnames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <CurrentIcon />
    </div>
  );
}
