import {createSelector} from "reselect";

export function selectFurtherOptionState(state) {
	return state.furtherOptionReducer;
}

export const selectFurtherOptions = createSelector(
	selectFurtherOptionState,
	state => state.arrFurtherOptions
);

export const getRelevantFurtherOptions = createSelector(
	[ selectFurtherOptions ],
	(furtherOptions) => {
		// We don't need second value (so just ignore it for now)
		return furtherOptions.filter(option => option.id !== 2)
	}
);
