import React from 'react';
import './App.css';
import { Router, Route, Switch } from 'react-router-dom';
import {Provider} from 'react-redux';
import { createBrowserHistory } from "history";
import MainRoute from "./components/MainRoute";
import configureStore from "./store/configureStore";
import FormNotFound from "./components/bits/PageNotFound";

const App = () => {
	// Configure redux store
	const store = configureStore();
	// Create browser history
	const history = createBrowserHistory();

	return (
		<Provider
			data-testid="redux-provider"
			store={store}
		>
			<Router history={history}>
				<Switch>
					<Route path="/" exact={true}>
						<MainRoute />
					</Route>
					<Route component={FormNotFound} />
				</Switch>
			</Router>
		</Provider>
	)
}
export default App;
