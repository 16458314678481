import React from 'react';
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

export default () => {
	return (
		<div data-testid="not-found">
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				style={{minHeight: '100vh'}}
			>
				<Grid item xs={12}>
					<Typography variant="h2">
						<span style={{color: 'red'}}>404</span> Page Not Found
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};
