import React, {Suspense, useState} from 'react';
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {useDispatch} from "react-redux";
import sendFormToApi from "../services/FormSubmitService";
import {StepActions} from "../store/stepReducer";
import {FormActions} from "../store/formReducer";
import formInitialValues from "../form/formInitialValues";
import Loading from "./bits/Loading";

const MainForm = ({step}) => {
	// Use dispatch hook
	const dispatch = useDispatch();
	// Use state hook
	const [initialValues] = useState(formInitialValues);

	/**
	 * Renders step component
	 * @param stepNo
	 * @returns {*}
	 */
	const renderStep = (stepNo) => {
		switch(stepNo) {
			case 1:
				const WelcomeStep = React.lazy(() => import('./WelcomeStep'));
				return <WelcomeStep />
			case 2:
				const ServicesStep = React.lazy(() => import('./ServicesStep'));
				return <ServicesStep />
			case 3:
				const AppointmentStep = React.lazy(() => import('./AppointmentStep'));
				return <AppointmentStep />
			case 4:
				const ClientStep = React.lazy(() => import('./ClientStep'));
				return <ClientStep />
			case 5:
				const VehicleStep = React.lazy(() => import('./VehicleStep'));
				return <VehicleStep />
			case 6:
				const OverviewStep = React.lazy(() => import('./OverviewStep'));
				return <OverviewStep />
			case 7:
				const ConfirmationStep = React.lazy(() => import('./ConfirmationStep'));
				return <ConfirmationStep />
			default:
				const WelcomeStepDefault = React.lazy(() => import('./WelcomeStep'));
				return <WelcomeStepDefault />
		}
	}

	// Add base validation schema
	const validationSchemaFields = {
		vehicleBrandId: Yup.number()
			.required('Required'),
		arrServiceOperationId: Yup.array()
			.required('Required'),
		clientEmail: Yup.string()
			.required('Required')
			.email('Invalid email address')
			.max(300),
		clientFirstName: Yup.string()
			.max(300),
		clientLastName: Yup.string()
			.required('Required')
			.max(300),
		clientStreetAndNumber: Yup.string()
			.required('Required')
			.max(300),
		clientPostalCode: Yup.string()
			.max(300),
		clientCity: Yup.string()
			.max(300),
		clientMobile: Yup.string()
			.required('Required')
			.max(300),
		clientTelephone: Yup.string()
			.max(300),
		vehicleModel: Yup.string()
			.required('Required')
			.max(300),
		vehicleRegistrationNumber: Yup.string()
			.required('Required')
			.max(300),
		vehicleKmCounter: Yup.string()
			.required('Required')
			.max(300),
		overviewAcceptTerms: Yup.boolean()
			.required('Required')
	}

	/**
	 * Handles form submit
	 * @param values
	 * @returns {Promise<void>}
	 */
	const handleSubmit = async (values) => {
		// At this point, form submission is pending
		dispatch(FormActions.setIsFormSubmissionPending(true));

		try {
			// Send form to API
			const response = await sendFormToApi(values);
			// Check if data exists
			if (response && response.isSuccess) {
				dispatch(StepActions.incrementStep());
			} else {
				dispatch(FormActions.setIsFormSubmissionError(true));
			}
		} catch (e) {
			dispatch(FormActions.setIsFormSubmissionError(true));
		} finally {
			// Form submission is no longer pending
			dispatch(FormActions.setIsFormSubmissionPending(false));
		}
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={Yup.object(validationSchemaFields)}
			onSubmit={async (values) => await handleSubmit(values)}
		>
			<Form>
				<Suspense fallback={<Loading marginTop={200} />}>
					{renderStep(step)}
				</Suspense>
			</Form>
		</Formik>
	)
}
export default MainForm;
