import ReduxThunk  from 'redux-thunk';
import {createStore, applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from "./reducer";

export default () => {
	return createStore(
		reducer,
		composeWithDevTools(
			applyMiddleware(ReduxThunk)
		));
}


