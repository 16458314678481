import { combineReducers } from "redux";
import {reducer as localeReducer} from "./localeReducer";
import {reducer as stepReducer} from "./stepReducer";
import {reducer as serviceTypeReducer} from "./serviceTypeReducer";
import {reducer as appointmentTimeTypeReducer} from "./appointmentTimeTypeReducer";
import {reducer as furtherOptionReducer} from "./furtherOptionReducer";
import {reducer as selectedDataReducer} from "./selectedDataReducer";
import {reducer as formReducer} from "./formReducer";

const reducer = combineReducers({
  localeReducer,
  stepReducer,
  serviceTypeReducer,
  appointmentTimeTypeReducer,
  furtherOptionReducer,
  selectedDataReducer,
  formReducer
});
export default reducer;

