import { createAction } from "../action";

export const ActionTypes = {
	SetIsFormSubmissionError: "SET_IS_FORM_SUBMISSION_ERROR",
	SetIsFormSubmissionPending: "SET_IS_FORM_SUBMISSION_PENDING"
}

export const Actions = {
	setIsFormSubmissionError: (payload) => createAction(ActionTypes.SetIsFormSubmissionError, payload),
	setIsFormSubmissionPending: (payload) => createAction(ActionTypes.SetIsFormSubmissionPending, payload),
};
