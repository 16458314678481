import { createSelector } from "reselect";

export function selectStepReducerState(state) {
	return state.stepReducer;
}

export const selectStep = createSelector(
	selectStepReducerState,
	state => state.step
);

export const selectTabs = createSelector(
	selectStepReducerState,
	state => state.tabs
);
