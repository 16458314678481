import React from 'react';
import {AppBar, Hidden, Toolbar, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

const Header = ({isVisible}) => {
	return (
		<>
			{isVisible && <AppBar color={"primary"} position={"relative"}>
				<Toolbar>
					<Hidden xsDown>
						<Typography variant="h4" color="inherit" >
							<FormattedMessage
								id="App.toolbarTitleXsDown"
								defaultMessage="BMW Online Naručivanje na Servis" />
						</Typography>
					</Hidden>
					<Hidden smUp>
						<Typography variant="h5" color="inherit" >
							<FormattedMessage
								id="App.toolbarTitleSmUp"
								defaultMessage="BMW Online Naručivanje na Servis" />
						</Typography>
					</Hidden>
				</Toolbar>
			</AppBar>}
		</>
	)
}

Header.defaultProps = {
	isVisible: true
}

export default Header;
