import axios from "axios";

/**
 * Sends for to API
 * @param formValues
 * @returns {Promise<{errorMessage: null, isSuccess: boolean}>}
 */
const sendFormToApi = async (formValues) => {
	// Check for faulty parameter
	if(!formValues) {
		return Promise.reject({
			isSuccess: false,
			errorMessage: 'From values object is not defined'
		});
	}

	try {
		// Get response
		await axios.post("/api/insertAppointment", {
			vehicleBrandId: formValues.vehicleBrandId,
			serviceOperationIds: formValues.arrServiceOperationId,
			workshopId: formValues.workshopId,
			appointmentDateTypeId: formValues.appointmentDateTypeId,
			appointmentDateTypeDate: formValues.appointmentDateTypeDate,
			appointmentTimeTypeId: formValues.appointmentTimeTypeId,
			appointmentDateTime: formValues.appointmentDateTime,
			mobilityId: formValues.mobilityId,
			note: formValues.note,
			appointmentId: formValues.appointmentId,
			clientEmail: formValues.clientEmail,
			clientFirstName: formValues.clientFirstName,
			clientLastName: formValues.clientLastName,
			clientStreetAndNumber: formValues.clientStreetAndNumber,
			clientPostalCode: formValues.clientPostalCode,
			clientCity: formValues.clientCity,
			clientCountry: formValues.clientCountry,
			clientMobile: formValues.clientMobile,
			clientTelephone: formValues.clientTelephone,
			clientLanguageId: formValues.clientLanguageId,
			vehicleModel: formValues.vehicleModel,
			vehicleRegistrationNumber: formValues.vehicleRegistrationNumber,
			vehicleIdentificationNumber: formValues.vehicleIdentificationNumber,
			vehicleRegistrationMonth: formValues.vehicleRegistrationMonth,
			vehicleRegistrationYear: formValues.vehicleRegistrationYear,
			vehicleKmCounter: formValues.vehicleKmCounter,
			overviewCreateUserAccount: formValues.overviewCreateUserAccount,
			overviewAcceptTerms: formValues.overviewAcceptTerms,
			furtherOptionsIds: formValues.arrFurtherOptionsId
		});

		return Promise.resolve({
			isSuccess: true,
			errorMessage: null
		});
	} catch (e) {
		return {
			isSuccess: false,
			errorMessage: e.errorMessage
		}
	}
}
export default sendFormToApi;
