import { createAction } from "../action";

export const ActionTypes = {
	IncrementStep: "INCREMENT_STEP",
	DecrementStep: "DECREMENT_STEP",
	ResetStep: "RESET_STEP"
}

export const Actions = {
	incrementStep: () => createAction(ActionTypes.IncrementStep),
	decrementStep: () => createAction(ActionTypes.DecrementStep),
	resetStep: () => createAction(ActionTypes.ResetStep)
};
