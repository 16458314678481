import { createAction } from "../action";

export const ActionTypes = {
  SetLocale: "SET_LOCALE",
  SetMessages: "SET_MESSAGES"
}

export const Actions = {
  setLocale: (payload) => createAction(ActionTypes.SetLocale, payload),
  setMessages: () => createAction(ActionTypes.SetMessages)
};


