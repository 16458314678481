import { createSelector } from "reselect";

export function selectServiceTypeReducerState(state) {
	return state.serviceTypeReducer;
}

export const selectServiceTypes = createSelector(
	selectServiceTypeReducerState,
	state => state.serviceTypes
);

export const getSelectedServiceTypeId = createSelector(
	selectServiceTypeReducerState,
	state => state.selectedServiceTypeId
);

export const getServiceTypesById = createSelector(
	[ selectServiceTypes ],
	(id, serviceTypes) => {
		return serviceTypes.filter(serviceType => serviceType.id === id)
	}
);
