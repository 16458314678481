const initialState = {
	appointmentTimeType: [{
		id: 1,
		name: "U bilo koje vrijeme"
	},{
		id: 2,
		name: "Prijepodne"
	},{
		id: 3,
		name: "Poslijepodne"
	}]
};

export default (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
}
