import {StepConnector, withStyles} from "@material-ui/core";
import theme from "../../config/theme";

const StyledStepConnector = withStyles({
	alternativeLabel: {
		top: 24,
	},
	active: {
		'& $line': {
			backgroundColor: theme.palette.primary.main
		},
	},
	completed: {
		'& $line': {
			backgroundColor: theme.palette.primary.main
		},
	},
	line: {
		height: 2,
		marginLeft: 15,
		marginRight: 15,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);
export default StyledStepConnector;
