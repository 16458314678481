import { createSelector } from "reselect";

export function selectLocaleReducerState(state) {
  return state.localeReducer;
}

export const selectLocale = createSelector(
  selectLocaleReducerState,
  state => state.locale
);

export const selectShortLocale = createSelector(
  selectLocaleReducerState,
  state => state.shortLocale
);

export const selectMessages = createSelector(
  selectLocaleReducerState,
  state => state.messages
);

export const selectDisplayLanguages = createSelector(
  selectLocaleReducerState,
  state => state.displayLanguages
);
