import {createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Roboto',
			'Arial',
			'"Helvetica Neue"',
			'Helvetica',
			'sans-serif'
		].join(','),
		fontSize: 13
	},
	palette: {
		primary: {main: '#1c69d4'},
		secondary: {main: '#ffad1f'}
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: 0
			},
		}
	},
});

let coef = 0.1;
const modifyRem = (value, coef) => {
	return `${parseFloat(value) * (1 + coef)}rem`;
};

// Iterate through typography properties and apply responsive font size
Object.entries(theme.typography).forEach(function(variant) {
	if(variant[0] === 'h3' || variant[0] === 'h4' || variant[0] === 'h5' || variant[0] === 'h6'
		|| variant[0] === 'body1' || variant[0] === 'body2') {
		theme.typography[variant[0]] = {
			...theme.typography[variant[0]],
			/*fontSize: modifyRem(variant.fontSize, -coef * 5),*/
			[theme.breakpoints.down('xs')]: {
				fontSize: modifyRem(theme.typography[variant[0]].fontSize, -coef * 1.4),
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: modifyRem(theme.typography[variant[0]].fontSize, -coef),
			}
		};
	}
});

export const bestAppointmentDivStyle = {
	marginLeft: 10,
	borderLeft: '5px solid #4caf50'
};

export const goodAppointmentDivStyle = {
	marginLeft: 10,
	borderLeft: '5px solid #ffad1f'
};

export const bestAppointmentLiStyle = {
	color: '#4caf50',
	fontSize: 17,
	fontWeight: 700,
	textTransform: 'uppercase'
};

export const goodAppointmentLiStyle = {
	color: '#ffad1f',
	fontSize: 17,
	fontWeight: 700,
	textTransform: 'uppercase'
};

export const divFormStyle = {
	paddingLeft: 40,
	paddingRight: 40
};

export const fclService = {
	marginLeft: 20
};

export const paperStyle = {
	margin: 10,
	padding: 20
};

export function calculatePaperStyle(isMediaSmMatch) {
	return isMediaSmMatch ? {
		marginTop: 15,
		marginBottom: 30,
		marginLeft: 5,
		marginRight: 5,
		} : {
		margin: 10,
		padding: 20
	}
}

export function calculateContentDivStyle(isMediaSmMatch) {
	return isMediaSmMatch ? {
		marginLeft: 3,
		marginRight: 3
	} : {
		marginLeft: 20,
		marginRight: 20
	}
}

export function calculateFormStyle(isMediaSmMatch) {
	return isMediaSmMatch ? {
		paddingLeft: 5,
		paddingRight: 5
	} : {
		paddingLeft: 40,
		paddingRight: 40
	}
}

export function calculateOverviewStyle(isMediaSmMatch) {
	return isMediaSmMatch ? {
		paddingLeft: 5,
		paddingRight: 5,
		marginTop: 20
	} : {
		paddingLeft: 40,
		paddingRight: 40,
		marginTop: 40
	}
}

export default theme;
