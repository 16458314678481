import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Get root element
const rootElem = document.getElementById('root')

ReactDOM.render(
    <App />, 
    rootElem
);

if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default
      ReactDOM.render(
        <NextApp />,
        rootElem
      )
    })
  }
