import { ActionTypes } from "./action";

const initialState = {
	isFormSubmissionError: false,
	isFormSubmissionPending: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.SetIsFormSubmissionError: {
			return {
				...state,
				isFormSubmissionError: action.payload
			};
		}
		case ActionTypes.SetIsFormSubmissionPending: {
			return {
				...state,
				isFormSubmissionPending: action.payload
			};
		}
		default:
			return state;
	}
}
