import { createAction } from "../action";

export const ActionTypes = {
	SetServiceTypeId: "SET_SERVICE_TYPE_ID",
	SetServiceTypes: "SET_SERVICE_TYPES"
}

export const Actions = {
	setServiceTypeId: (payload) => createAction(ActionTypes.SetServiceTypeId, payload),
	setServiceTypes: (payload) => createAction(ActionTypes.SetServiceTypes, payload)
};
