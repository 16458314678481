import { ActionTypes } from "./action";
import intlMessagesHR from "../../translations/hr.json";
import intlMessagesEN from "../../translations/en.json";

const croLocale = 'hr_HR';
const croLocaleShort = 'hr';
const engLocale = 'en_US';
const engLocaleShort = 'en';

const initialState = {
  displayLanguages: [{
    id: 1,
    name: 'Hrvatski',
    locale: croLocale,
    shortLocale: croLocaleShort
  }, {
    id: 2,
    name: 'Engleski',
    locale: engLocale,
    shortLocale: engLocaleShort
  }],
  locale: croLocale,
  shortLocale: croLocaleShort,
  messages: intlMessagesHR
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetLocale: {
      let messages = intlMessagesHR;
      let locale = croLocale;
      let shortLocale = croLocaleShort;
      // Switch language value
      switch (action.payload) {
        case engLocale: {
          messages = intlMessagesEN;
          locale = engLocale;
          shortLocale = engLocaleShort;
          break;
        }
        default: {
          messages = intlMessagesHR;
          locale = croLocale;
          break;
        }
      }

      return {
        ...state,
        locale,
        shortLocale,
        messages
      };
    }
    case ActionTypes.SetMessages: {
      return {
        ...state,
        messages: action.payload
      };
    }
    default:
      return state;
  }
}
