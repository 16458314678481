import React from "react";
import {Stepper, Step, StepLabel} from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
import Hidden from "@material-ui/core/Hidden";
import StyledStepConnector from "./StyledStepConnector";
import CustomStepIcon from "./CustomStepIcon";

const ServiceNavigation = ({isVisible, step, tabs}) => {
	return (
		<>
			{isVisible &&
				<Hidden xsDown>
					<div
						style={{marginBottom: 20, marginTop: 30}}
						data-testid="service-navigation"
					>
						<Stepper activeStep={step - 1} alternativeLabel connector={<StyledStepConnector />}>
							{tabs.map(tab => {
								let id = "ServiceNavigation.tabText" + tab.tabNumber;
								return (
									<Step key={tab.tabNumber}>
										<StepLabel StepIconComponent={CustomStepIcon}>
											<FormattedMessage id={id} defaultMessage={tab.tabText} />
										</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					</div>
				</Hidden>
			}
		</>
	)
}

ServiceNavigation.defaultProps = {
	isVisible: true,
	step: 1,
	tabs: []
}

export default ServiceNavigation;
