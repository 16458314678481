import { ActionTypes } from "./action";

export const initialState = {
	vehicleBrandName: '',
	serviceOperations: [],
	workshopLocationName: '',
	workshopLocationAddress: '',
	appointmentDateTime: '',
	clientLanguageName: '',
	serviceDurationDays: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.SetVehicleBrandName: {
			return {
				...state,
				vehicleBrandName: action.payload
			};
		}
		case ActionTypes.AddServiceOperation: {
			// Get current names
			let ops = state.serviceOperations;
			// Check if name already exists
			const operation = ops.find(operation => operation.id === action.payload.id);
			// Check for non existence
			if(!operation) {
				ops.push(action.payload);
			}
			// Return new state
			return {
				...state,
				serviceOperations: ops
			};
		}
		case ActionTypes.RemoveServiceOperation: {
			// Get current service operations
			let ops = state.serviceOperations;
			// Filter out operation and return new array
			const newOperations = ops.filter(operation => operation.id !== action.payload.id);
			// Return new state
			return {
				...state,
				serviceOperations: newOperations
			};
		}
		case ActionTypes.SetWorkshopLocationName: {
			// Return new state
			return {
				...state,
				workshopLocationName: action.payload
			};
		}
		case ActionTypes.SetWorkshopLocationAddress: {
			// Return new state
			return {
				...state,
				workshopLocationAddress: action.payload
			};
		}
		case ActionTypes.SetAppointmentDateTime: {
			// Return new state
			return {
				...state,
				appointmentDateTime: action.payload
			};
		}
		case ActionTypes.SetClientLanguageName: {
			// Return new state
			return {
				...state,
				clientLanguageName: action.payload
			};
		}
		case ActionTypes.SetServiceDurationDays: {
			// Return new state
			return {
				...state,
				serviceDurationDays: action.payload
			};
		}
		default:
			return state;
	}
}
