import { ActionTypes } from "./action";

export const initialState = {
	tabs: [{
		tabNumber: 1,
		tabText: "Marka"
	}, {
		tabNumber: 2,
		tabText: "Usluga"
	}, {
		tabNumber: 3,
		tabText: "Termin"
	}, {
		tabNumber: 4,
		tabText: "Klijent"
	}, {
		tabNumber: 5,
		tabText: "Vozilo"
	}, {
		tabNumber: 6,
		tabText: "Pregled"
	}, {
		tabNumber: 7,
		tabText: "Potvrda"
	}],
	step: 1
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.IncrementStep: {
			return {
				...state,
				step: state.step + 1
			};
		}
		case ActionTypes.DecrementStep: {
			const step = state.step > 1
				? state.step - 1 : state.step
			return {
				...state,
				step
			};
		}
		case ActionTypes.ResetStep: {
			return {
				...state,
				step: initialState.step
			}
		}
		default:
			return state;
	}
}
