import { ActionTypes } from "./action";

const initialState = {
	serviceTypes: [],
	selectedServiceTypeId: 1,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.SetServiceTypes: {
			return {
				...state,
				serviceTypes: action.payload
			};
		}
		case ActionTypes.SetServiceTypeId: {
			// Set default value as current value
			let selectedServiceTypeId = state.selectedServiceTypeId;
			// Check if valid number
			if(!isNaN(action.payload) && action.payload >= 0) {
				selectedServiceTypeId = action.payload
			}
			return {
				...state,
				selectedServiceTypeId
			};
		}
		default:
			return state;
	}
}
