import { createSelector } from "reselect";
import moment from "moment";

export function selectSelectedDataReducerState(state) {
	return state.selectedDataReducer;
}

export const getVehicleBrandName = createSelector(
	selectSelectedDataReducerState,
	state => state.vehicleBrandName
);

export const getServiceOperations = createSelector(
	selectSelectedDataReducerState,
	state => state.serviceOperations
);

export const getWorkshopLocationName = createSelector(
	selectSelectedDataReducerState,
	state => state.workshopLocationName
);

export const getWorkshopLocationAddress = createSelector(
	selectSelectedDataReducerState,
	state => state.workshopLocationAddress
);

export const getClientLanguageName = createSelector(
	selectSelectedDataReducerState,
	state => state.clientLanguageName
);

export const getAppointmentDateTime = createSelector(
	selectSelectedDataReducerState,
	state => state.appointmentDateTime
);

export const getFormattedAppointmentDateTime = createSelector(
	selectSelectedDataReducerState,
	state =>
		moment(state.appointmentDateTime).format('dddd, DD. MMMM YYYY, HH:mm')
);

export const getServiceDurationDays = createSelector(
	selectSelectedDataReducerState,
	state => state.serviceDurationDays
);
