import { createSelector } from "reselect";

export function selectFormReducerState(state) {
	return state.formReducer;
}

export const getIsFormSubmissionError = createSelector(
	selectFormReducerState,
	state => state.isFormSubmissionError
);

export const getIsFormSubmissionPending = createSelector(
	selectFormReducerState,
	state => state.isFormSubmissionPending
);
