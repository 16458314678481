const initialState = {
	arrFurtherOptions: [{
		id: 1,
		name: "Želim čekati da se popravak na mom vozilu dovrši",
	}, {
		id: 2,
		name: "Potrebna mi je mobilnost",
	}, {
		id: 3,
		name: "Želim poziv auto-kuće",
	}],
};

export default (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
}
