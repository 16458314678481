import React from "react";
import Header from "./header/Header";
import ServiceNavigation from "./navigation/ServiceNavigation";
import MainForm from "./MainForm";
import {Footer} from "./bits/Footer";
import {useSelector} from 'react-redux'
import {selectMessages, selectShortLocale} from "../store/localeReducer";
import {selectStep, selectTabs} from "../store/stepReducer";
import {IntlProvider } from "react-intl";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme, {calculateContentDivStyle} from "../config/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MainRoute = () => {
	// Get current step
	const step = useSelector(selectStep);
	// Get current locale
	const shortLocale = useSelector(selectShortLocale);
	// Get translation messages
	const messages = useSelector(selectMessages);
	// Get translation messages
	const tabs = useSelector(selectTabs);
	// Use XS media query hook
	const isMediaSmMatch = useMediaQuery(theme.breakpoints.only('xs'));
	// Calculate content div style
	const contentDivStyle = calculateContentDivStyle(isMediaSmMatch);

	return (
		<>
			<IntlProvider
				key={shortLocale}
				locale={shortLocale}
				messages={messages}>
				<div
					id={"contentWrapper"}
					data-testid='main-route'
				>
					<MuiThemeProvider theme={theme}>
						<div id={"divAppBody"}>
							<Header isVisible={step > 1} />
							<ServiceNavigation
								isVisible={step > 1}
								step={step}
								tabs={tabs}
							/>
							<div id={"divContent"} style={contentDivStyle}>
								<MainForm step={step} />
							</div>
						</div>
						<Footer />
					</MuiThemeProvider>
				</div>
			</IntlProvider>
		</>
	)
}
export default MainRoute;
