import {createSelector} from "reselect";

export function selectAppointmentTimeTypeState(state) {
	return state.appointmentTimeTypeReducer;
}

export const selectAppointmentTimeTypes = createSelector(
	selectAppointmentTimeTypeState,
	state => state.appointmentTimeType
);
