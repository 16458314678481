const formInitialValues = {
	vehicleBrandId: '',
	arrServiceOperationId: [],
	workshopId: '',
	appointmentDateTypeId: 1,
	appointmentDateTypeDate: new Date(),
	appointmentTimeTypeId: 1,
	arrFurtherOptionsId: [],
	mobilityId: '',
	note: '',
	arrFile: [],
	appointmentTermId: 1,
	appointmentDateTime: '',
	clientEmail: '',
	clientFirstName: '',
	clientLastName: '',
	clientStreetAndNumber: '',
	clientPostalCode: '',
	clientCity: '',
	clientCountry: '',
	clientMobile: '',
	clientTelephone: '',
	clientLanguage: '',
	clientLanguageId: '',
	vehicleModel: '',
	vehicleRegistrationNumber: '',
	vehicleIdentificationNumber: '',
	vehicleRegistrationMonth: '',
	vehicleRegistrationYear: '',
	vehicleKmCounter: '',
	overviewCreateUserAccount: 0,
	overviewAcceptTerms: false
}
export default formInitialValues;
